import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { googleTagManager } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MortgageHolidayCalcComponent } from './pages/mortgage-holiday-calc/mortgage-holiday-calc.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { Icons } from './components/icons';

@NgModule( {
  declarations: [
    AppComponent,
    MortgageHolidayCalcComponent,
    HeaderComponent,
    ...Icons
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    ...Icons
  ],
  providers: [
    { provide: 'googleTagManagerId', useValue: googleTagManager.id }
  ],
  bootstrap: [AppComponent]
} )
export class AppModule { }
