<div class="{{siteKey}} topnav-container">
  <div class="container">
    <div class="wrapper">
      <!-- <img class="bars" src="../../../assets/icons/svg/icon-bars.svg" (click)="showSidebar = true;" /> -->
      <!-- <div class="logo-text" (click)="openLink('https://myadviser.co.nz/')"> -->
      <div class="logo-text">
        <!-- My<span>Adviser</span> -->
        <img class="logo" src="../../../assets/icons/svg/logo.svg" width="146" height="42" />
      </div>
      <!-- <img class="logo" src="../../../assets/icons/png/{{siteKey}}-logo.png" /> -->
      <!-- <div class="link-container" [class.show-sidebar]="showSidebar">
        <div class="link-wrapper">
          <div class="times-wrapper">
            <img class="times" src="../../../assets/icons/svg/icon-times.svg" (click)="showSidebar = false;" />
          </div>
          <div class="link" (click)="openLink('https://myadviser.co.nz/')">Home</div>
          <div class="link" (click)="openLink('https://myadviser.co.nz/find-adviser')">Find an Adviser</div>
          <div class="link" (click)="openLink('https://myadviser.co.nz/faqs')">FAQs</div>
          <div class="link" (click)="openLink('https://myadviser.co.nz/contact-us')">Contact Us</div>
          <button class="adviser-login-btn" (click)="openLink('https://myadviser.co.nz/')">Adviser Login</button>
        </div>
      </div> -->
    </div>
  </div>
</div>
