import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component( {
  // tslint:disable-next-line: component-selector
  selector: 'icon-blank',
  templateUrl: './icon-blank.component.html',
  styles: []
} )
export class IconBlankComponent implements AfterViewInit {

  @Input() color;

  @ViewChild( 'iconBlank' ) el: ElementRef;

  ngAfterViewInit () {
    if ( this.el ) {
      this.el.nativeElement.style.fill = `#${this.color}`;
    }
  }

}
