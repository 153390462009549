<app-header *ngIf="!isIframe"></app-header>

<div class="{{siteKey}} mortgage-deferment-calculator">
  <div class="container">
    <div class="wrapper">
      <div class="calculator-container" [class.open]="isMobileTablet && !openResults">
        <h3>{{country === COUNTRIES.AUSTRALIA ? 'Payment Pause' : 'Mortgage Deferment' }} Calculator</h3>
        <p>Use this calculator to work out how much a deferment (also known as a payment pause, or holiday) will really
          cost you.</p>

        <form [formGroup]="calculatorForm">
          <div class="form-group text-box">
            <div class="text">
              Current Balance
            </div>
            <input class="form-control" type="text" formControlName="currentBalance" data-type="currency"
              pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" inputmode="decimal" placeholder="$0.00"
              (keyup)="formatCurrentBalance()" (blur)="formatCurrentBalance(true)" />
          </div>
          <div class="form-group text-box text-box-tr">
            <div class="text">
              Time Remaining
            </div>
            <div class="selection-container">
              <input type="checkbox" id="remaining-years" #remainingYearsId />
              <label class="selection-wrapper" for="remaining-years" tabindex="-1"
                (focusout)="resetSelectOption('remainingYears')">
                <div class="selected-text">
                  <span class="selected"
                    *ngIf="calculatorControls.remainingYear.value">{{calculatorControls.remainingYear.value}} year<span
                      *ngIf="calculatorControls.remainingYear.value > 1">s</span></span>
                  <span class="no-selection" *ngIf="!calculatorControls.remainingYear.value">0 years</span>
                </div>
                <img class="caret" src="../../../assets/icons/svg/icon-arrow-left-default.svg" />

                <div id="remaining-years-options" class="selection-options">
                  <div class="option" (click)="selectRemainingYear(0)">0 years</div>
                  <div class="option" *ngFor="let year of remainingYearsOptions"
                    [class.current-selected]="year === calculatorControls.remainingYear.value"
                    (click)="selectRemainingYear(year)">
                    {{year}} year<span *ngIf="year > 1">s</span></div>
                </div>
              </label>
              <input type="checkbox" id="remaining-months" #remainingMonthsId />
              <label class="selection-wrapper" for="remaining-months" tabindex="-1"
                (focusout)="resetSelectOption('remainingMonths')">
                <div class="selected-text">
                  <span class="selected"
                    *ngIf="calculatorControls.remainingMonth.value">{{calculatorControls.remainingMonth.value}}
                    month<span *ngIf="calculatorControls.remainingMonth.value > 1">s</span></span>
                  <span class="no-selection" *ngIf="!calculatorControls.remainingMonth.value">0 months</span>
                </div>
                <img class="caret" src="../../../assets/icons/svg/icon-arrow-left-default.svg" />

                <div class="selection-options">
                  <div class="option" (click)="selectRemainingMonth(0)">0 months</div>
                  <div class="option" *ngFor="let month of remainingMonthsOptions"
                    [class.current-selected]="month === calculatorControls.remainingMonth.value"
                    (click)="selectRemainingMonth(month)">
                    {{month}} month<span *ngIf="month > 1">s</span></div>
                </div>
              </label>
            </div>
          </div>
          <div class="form-group text-box">
            <div class="text">
              Interest Rate %
            </div>
            <input class="form-control" type="text" formControlName="interestRate" inputmode="decimal"
              placeholder="0.00" (keyup)="formatInterestRate()" (blur)="formatInterestRate(true)" />
          </div>
          <div class="form-group text-box margin-bottom" [class.error]="(calculatorControls.monthsHoliday.dirty ||
          calculatorControls.monthsHoliday.touched) && !isMonthsHolidayValid()">
            <div class="text">
              Months {{country === COUNTRIES.AUSTRALIA ? 'Pause' : 'Holiday'}}
            </div>
            <input class="form-control" type="text" formControlName="monthsHoliday" inputmode="decimal" placeholder="0"
              (keyup)="formatMonthsHoliday()" (blur)="formatMonthsHoliday(true)" />
          </div>
          <div class="radio-box-label">What are you interested in?</div>
          <div class="radio-box">
            <label class="form-group" for="option-1">
              <div class="radio-icon"><input class="form-control" id="option-1" type="radio" value="1"
                  formControlName="option" />
                <div class="selected"></div>
              </div>
              {{country === COUNTRIES.AUSTRALIA ? 'Pause my Principal & Interest repayments' : 'Principal & Interest - Mortgage Deferment' }}
            </label>
            <label class="form-group" for="option-2">
              <div class="radio-icon"><input class="form-control" id="option-2" type="radio" value="2"
                  formControlName="option" />
                <div class="selected"></div>
              </div>
              {{country === COUNTRIES.AUSTRALIA ? 'Temporarily change to Interest Only repayments' : 'Principal & Interest - To Interest Only' }}
            </label>
            <label class="form-group">
              <div class="radio-icon"><input class="form-control" id="option-3" type="radio" value="3"
                  formControlName="option" />
                <div class="selected"></div>
              </div>
              {{country === COUNTRIES.AUSTRALIA ? 'Pause my Interest Only repayments' : 'Interest Only - Mortgage Deferment' }}
            </label>
          </div>

          <button class="calculate-btn" (click)="calculate()"
            [disabled]="!canCalculate()">{{isCalculating ? 'Calculating...' : 'Calculate'}}</button>
        </form>

        <div class="alert-container" *ngIf="(calculatorControls.monthsHoliday.dirty ||
        calculatorControls.monthsHoliday.touched) && !isMonthsHolidayValid()">
          <img src="../../../assets/icons/svg/icon-warning.svg">
          <p>Length of {{siteKey === 'myAdviser' ?  'holiday' : 'pause'}} must be less than the mortgage term.</p>
        </div>
      </div>
      <div class="result-container" [class.open]="isMobileTablet && openResults">

        <div class="mortgage-details-mobile">
          <div class="details">
            <img src="../../../assets/icons/svg/icon-{{siteKey}}-arrow-left-secondary.svg"
              (click)="openResults = !openResults;" />Edit Mortgage Details</div>
          <div class="tab-container">
            <div class="tab-wrapper" [class.selected]="selectedTab === RESULT_TABS.RESULTS"
              (click)="selectedTab = RESULT_TABS.RESULTS"> <img *ngIf="siteKey !== siteKeyRef.byob"
                src="../../../assets/icons/svg/icon-{{siteKey}}-results-{{selectedTab === RESULT_TABS.RESULTS ? 'selected' : 'default'}}.svg" />
              <icon-results *ngIf="siteKey === siteKeyRef.byob"
                [color]="selectedTab === RESULT_TABS.RESULTS ? 'FFFFFF' : hexColor">
              </icon-results>
              Results
            </div>
            <div class="tab-wrapper" [class.selected]="selectedTab === RESULT_TABS.PAYMENT_TABLE"
              (click)="selectedTab = RESULT_TABS.PAYMENT_TABLE;"> <img *ngIf="siteKey !== siteKeyRef.byob"
                src="../../../assets/icons/svg/icon-{{siteKey}}-payment-table-{{selectedTab === RESULT_TABS.PAYMENT_TABLE ? 'selected' : 'default'}}.svg" />
              <icon-payment-table *ngIf="siteKey === siteKeyRef.byob"
                [color]="selectedTab === RESULT_TABS.PAYMENT_TABLE ? 'FFFFFF' : hexColor">
              </icon-payment-table>
              Payment Table
            </div>
          </div>
        </div>
        <div class="payments-container" [class.tab-open]="isMobileTablet && selectedTab === RESULT_TABS.RESULTS">
          <div class="payments-header">
            <h5>Payments:</h5>
            <div class="selection-container" [class.disabled]="!result">
              <input type="checkbox" id="payment-options" #paymentOptionsId />
              <label class="selection-wrapper" for="payment-options" tabindex="-1"
                (focusout)="resetSelectOption('paymentOptions')">
                <div class="selected-text">
                  <span class="selected">{{selectedPayment.name}}</span>
                </div>
                <img class="caret" src="../../../assets/icons/svg/icon-arrow-left-default.svg" />

                <div class="selection-options">
                  <div class="option" *ngFor="let option of paymentOptions"
                    [class.current-selected]="option.id === selectedPayment.id" (click)="getPaymentResult(option)">
                    {{option.name}} </div>
                </div>
              </label>
            </div>
          </div>
          <div class="blank-calculation-container" *ngIf="!result">
            <div class="blank-calculation-wrapper">
              <div class="content-wrapper">
                <img *ngIf="siteKey === siteKeyRef.myAdviser"
                  src="../../../assets/icons/svg/icon-{{siteKey}}-blank-payment.svg" />
                <icon-blank *ngIf="siteKey !== siteKeyRef.myAdviser"
                  [color]="siteKey === siteKeyRef.byob ? hexColor : LM_COLOR_DEFAULTS.BLUE"></icon-blank>
                <p>Enter your mortgage details and hit calculate</p>
              </div>
            </div>
            <div class="blank-bar">
              <div class="blank-left">
                <div class="empty"></div>
              </div>
              <div class="blank-right">
                <div class="empty"></div>
              </div>
            </div>
          </div>

          <div class="payments-content" *ngIf="result">
            <div class="payment-table">
              <div class="payment-table-row">
                <div class="payment-table-column"></div>
                <div class="payment-table-column">Before Holiday</div>
                <div class="payment-table-column">After Holiday</div>
              </div>
              <div class="payment-table-row">
                <div class="payment-table-column">Principal</div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.beforeHoliday.principal, true)}}</div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.afterHoliday.principal, true)}}</div>
              </div>
              <div class="payment-table-row">
                <div class="payment-table-column">Interest</div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.beforeHoliday.interest, true)}}</div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.afterHoliday.interest, true)}}</div>
              </div>
              <div class="payment-table-row">
                <div class="payment-table-column">Total Payment</div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.beforeHoliday.totalPayment, true)}}
                </div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.afterHoliday.totalPayment, true)}}
                </div>
              </div>
              <div class="payment-table-row">
                <div class="payment-table-column">Loan Balance</div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.beforeHoliday.loanBalance, true)}}
                </div>
                <div class="payment-table-column">{{formatCurrency(paymentResults.afterHoliday.loanBalance, true)}}
                </div>
              </div>
            </div>
            <div class="payment-bars-wrapper">
              <div class="payment-bar">
                <div class="payment-bar-left">Payment Increase</div>
                <div class="payment-bar-right"><span>+{{formatCurrency(paymentResults.paymentIncrease, true)}}</span>
                  <icon-arrow-up *ngIf="parseNumber(paymentResults?.paymentIncrease)"
                    [color]="siteKey === siteKeyRef.byob ? LM_COLOR_DEFAULTS.BLACK : 'FFFFFF'"></icon-arrow-up>
                </div>
              </div>
              <div class="payment-bar">
                <div class="payment-bar-left">Balance Increase</div>
                <div class="payment-bar-right"><span>+{{formatCurrency(paymentResults.balanceIncrease, true)}}</span>
                  <icon-arrow-up *ngIf="parseNumber(paymentResults?.balanceIncrease)"
                    [color]="siteKey === siteKeyRef.byob ? LM_COLOR_DEFAULTS.BLACK : 'FFFFFF'"></icon-arrow-up>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="year-container" [class.tab-open]="isMobileTablet && selectedTab === RESULT_TABS.PAYMENT_TABLE"
          *ngIf="result">
          <div class="year-header">
            <h5>Year:</h5>
            <div class="selection-container">
              <input type="checkbox" id="year-options" #yearOptionsId />
              <label class="selection-wrapper" for="year-options" tabindex="-1"
                (focusout)="resetSelectOption('yearOptions')">
                <div class="selected-text">
                  <span class="selected">{{selectedYear.name}}</span>
                </div>
                <img class="caret" src="../../../assets/icons/svg/icon-arrow-left-default.svg" />

                <div class="selection-options">
                  <div class="option" *ngFor="let option of yearOptions"
                    [class.current-selected]="option.year === selectedYear.year" (click)="getYearResult(option)">
                    {{option.name}} </div>
                </div>
              </label>
            </div>
          </div>

          <div class="years-content">
            <div class="year-table" [class.show-right-result]="showLeftArrow">
              <div class="circle-arrow circle-arrow-right" *ngIf="!showLeftArrow"
                (click)="showLeftArrow = !showLeftArrow"><img
                  src="../../../assets/icons/svg/icon-{{siteKey}}-arrow-left-secondary.svg" />

              </div>
              <div class="circle-arrow circle-arrow-left" *ngIf="showLeftArrow"
                (click)="showLeftArrow = !showLeftArrow"><img
                  src="../../../assets/icons/svg/icon-{{siteKey}}-arrow-left-secondary.svg" />
              </div>
              <div class="year-table-row">
                <div class="year-table-column"></div>
                <div class="year-table-column">Interest</div>
                <div class="year-table-column">Principal</div>
                <div class="year-table-column">Payment</div>
                <div class="year-table-column">End Balance</div>
              </div>
              <div class="year-table-row" *ngFor="let year of yearResults">
                <div class="year-table-column">{{abbreviateMonth(year.monthName)}}</div>
                <div class="year-table-column">{{formatCurrency(year.interest, true)}}</div>
                <div class="year-table-column">{{formatCurrency(year.principal, true)}}</div>
                <div class="year-table-column">{{formatCurrency(year.payment, true)}}</div>
                <div class="year-table-column">{{formatCurrency(year.endBalance, true)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
