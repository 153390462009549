import { Component, OnInit, Input } from '@angular/core';

@Component( {
  // tslint:disable-next-line: component-selector
  selector: 'icon-payment-table',
  templateUrl: './icon-payment-table.component.html',
  styles: []
} )
export class IconPaymentTableComponent implements OnInit {

  @Input()
  set color ( hexVal ) {
    document.getElementById( 'icon-payment-table' ).setAttribute( 'fill', `#${hexVal}` );
  }

  constructor () { }

  ngOnInit () {
  }

}
