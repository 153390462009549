import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// environment
import { siteKey } from '../environments/environment';

// pages
import { MortgageHolidayCalcComponent } from './pages/mortgage-holiday-calc/mortgage-holiday-calc.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'deferment-calc',
    pathMatch: 'full'
  },
  {
    path: 'deferment-calc',
    component: MortgageHolidayCalcComponent,
    data: {
      key: siteKey.myAdviser,
      pageTitle: 'Mortgage Deferment Calculator',
      iframe: false
    }
  },
  {
    path: 'deferment-calc-lm',
    component: MortgageHolidayCalcComponent,
    data: {
      key: siteKey.loanMarket,
      pageTitle: 'Mortgage Deferment Calculator (LM)',
      iframe: false
    }
  },
  {
    path: 'deferment-calc-lm-iframe',
    component: MortgageHolidayCalcComponent,
    data: {
      key: siteKey.loanMarket,
      pageTitle: '',
      iframe: true

    }
  },
  {
    path: 'deferment-calc-byob-iframe',
    component: MortgageHolidayCalcComponent,
    data: {
      key: siteKey.byob,
      pageTitle: '',
      iframe: true

    }
  },

  {
    path: '**',
    redirectTo: 'deferment-calc',
    pathMatch: 'full'
  }
];

@NgModule( {
  imports: [RouterModule.forRoot( routes, {
    onSameUrlNavigation: 'reload',
    useHash: false
  } )],
  exports: [RouterModule]
} )
export class AppRoutingModule { }
