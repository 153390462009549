import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class MortgageHolidayCalcService {

  baseUrl = environment.apiUrl;

  constructor ( private http: HttpClient ) { }

  getResults ( params ): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/mortgage-payments`, { params }
    );
  }
}
