export const PAYMENTS_OPTIONS = [
  { id: 1, name: 'Monthly' },
  { id: 2, name: 'Fortnightly' }
];

export const RESULT_TABS = {
  RESULTS: 1,
  PAYMENT_TABLE: 2
};

export const LM_COLOR_DEFAULTS = {
  BLUE: '4ea8e0',
  ORANGE: 'FF7630',
  GREEN: '00A185',
  RED: 'E17878',
  BLACK: '303030'
};

export const COUNTRIES = {
  NEW_ZEALAND: 'NZ',
  AUSTRALIA: 'AU'
};
