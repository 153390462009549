import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

// Import the functions you need from the SDKs you need
import firebase from 'firebase';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwA23wwLjynFyLPUl6stNu561_Z-_6mtk",
  authDomain: "mortgage-deferment-calc.firebaseapp.com",
  projectId: "mortgage-deferment-calc",
  storageBucket: "mortgage-deferment-calc.appspot.com",
  messagingSenderId: "582536711029",
  appId: "1:582536711029:web:bfe2a6facb1fa37e0a213c",
  measurementId: "G-6SPE095Z63"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
