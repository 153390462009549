import { Component, OnInit } from '@angular/core';

// services
import { FlagCheckerService } from '../../services/flag-checker/flag-checker.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component( {
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
} )
export class HeaderComponent implements OnInit {

  siteKey;
  showSidebar = false;


  constructor (
    private flagCheckerService: FlagCheckerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

    this.siteKey = activatedRoute.snapshot.data.key;
  }

  ngOnInit () {
    // this.siteKey = this.flagCheckerService.getSiteKey();
  }


  openLink ( link ) {
    window.open( link, '_self' );
  }

}
