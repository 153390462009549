<svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
  <g id="calculator" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g #iconPaymentTable id="icon-payment-table" transform="translate(-44.000000, -184.000000)" fill="#FF7630">
      <g id="icn-table" transform="translate(44.000000, 184.000000)">
        <path
          d="M0,0.42989639 L0,13.3267881 C0,13.564213 0.149238667,13.7566845 0.333333333,13.7566845 L17.6666667,13.7566845 C17.8507613,13.7566845 18,13.564213 18,13.3267881 L18,0.42989639 C18,0.192471492 17.8507613,-4.54747351e-13 17.6666667,-4.54747351e-13 L0.333333333,-4.54747351e-13 C0.149238667,-4.54747351e-13 0,0.192471492 0,0.42989639 Z M0.666666667,0.859792781 L8.66666667,0.859792781 L8.66666667,4.2989639 L0.666666667,4.2989639 L0.666666667,0.859792781 Z M9.33333333,0.859792781 L17.3333333,0.859792781 L17.3333333,4.2989639 L9.33333333,4.2989639 L9.33333333,0.859792781 Z M0.666666667,5.15875668 L8.66666667,5.15875668 L8.66666667,8.59792781 L0.666666667,8.59792781 L0.666666667,5.15875668 Z M9.33333333,5.15875668 L17.3333333,5.15875668 L17.3333333,8.59792781 L9.33333333,8.59792781 L9.33333333,5.15875668 Z M0.666666667,9.45772059 L8.66666667,9.45772059 L8.66666667,12.8968917 L0.666666667,12.8968917 L0.666666667,9.45772059 Z M9.33333333,9.45772059 L17.3333333,9.45772059 L17.3333333,12.8968917 L9.33333333,12.8968917 L9.33333333,9.45772059 Z"
          id="Shape"></path>
      </g>
    </g>
  </g>
</svg>
