import { IconResultsComponent } from './icon-results/icon-results.component';
import { IconPaymentTableComponent } from './icon-payment-table/icon-payment-table.component';
import { IconBlankComponent } from './icon-blank/icon-blank.component';
import { IconArrowUpComponent } from './icon-arrow-up/icon-arrow-up.component';

export const Icons = [
  IconResultsComponent,
  IconPaymentTableComponent,
  IconBlankComponent,
  IconArrowUpComponent
];
