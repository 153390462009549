<svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
  <g id="calculator" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g #iconResults id="icon-results" transform="translate(-65.000000, -184.000000)" fill="#FF7630" fill-rule="nonzero">
      <g transform="translate(65.000000, 184.000000)">
        <path
          d="M4.83632996,13.9985352 C5.1854089,13.9985352 5.53660346,13.9985352 5.8856824,13.9985352 C6.40612735,13.9985352 6.92657231,13.9985352 7.44701727,13.9985352 C8.08170624,13.9985352 8.71639521,13.9985352 9.35108418,13.9985352 C10.0428952,13.9985352 10.7347061,13.9985352 11.4265171,13.9985352 C12.1289062,13.9985352 12.8312954,13.9985352 13.5336845,13.9985352 C14.1874141,13.9985352 13.1289737,13.9985352 13.7827033,13.9985352 C14.3327671,13.9985352 14.8849465,13.9985352 15.4350103,13.9985352 C15.8327487,13.9985352 16.2326028,13.9985352 16.6303412,13.9985352 C16.8186323,13.9985352 17.0069233,14.0009766 17.19733,13.9985352 C17.2057925,13.9985352 17.2142551,13.9985352 17.2206019,13.9985352 C17.5527558,13.9985352 17.8701003,13.6616211 17.8552909,13.2661133 C17.8404815,12.8681641 17.5760278,12.5336914 17.2206019,12.5336914 C17.0915485,12.5336914 16.9624951,12.5336914 16.831326,12.5336914 C16.4822471,12.5336914 16.1310525,12.5336914 15.7819736,12.5336914 C15.2615286,12.5336914 14.7410837,12.5336914 14.2206387,12.5336914 C13.5859498,12.5336914 14.6655465,12.5336914 14.0308575,12.5336914 C13.3390466,12.5336914 12.6472356,12.5336914 11.9554246,12.5336914 C11.2530355,12.5336914 10.5506463,12.5336914 9.84825721,12.5336914 C9.19452757,12.5336914 8.5386823,12.5336914 7.88495266,12.5336914 C7.33488888,12.5336914 6.78270948,12.5336914 6.2326457,12.5336914 C5.83490728,12.5336914 5.43505323,12.5336914 5.03731481,12.5336914 C4.84902374,12.5336914 4.66073268,12.53125 4.47032599,12.5336914 C4.46186347,12.5336914 4.45340095,12.5336914 4.44705406,12.5336914 C4.11490017,12.5336914 3.79755568,12.8706055 3.81236509,13.2661133 C3.8271745,13.6640625 4.09162824,13.9985352 4.44705406,13.9985352 C4.57610749,13.9985352 4.70516091,13.9985352 4.83632996,13.9985352 Z"
          id="Path"></path>
        <path
          d="M0.655845271,13.9985352 C1.12763074,13.9985352 1.59941621,13.9985352 2.07120168,13.9985352 C2.40335557,13.9985352 2.72070006,13.6616211 2.70589065,13.2661133 C2.69108124,12.8681641 2.4266275,12.5336914 2.07120168,12.5336914 C1.59941621,12.5336914 1.12763074,12.5336914 0.655845271,12.5336914 C0.323691376,12.5336914 0.00634688972,12.8706055 0.0211562991,13.2661133 C0.0359657084,13.6640625 0.298303817,13.9985352 0.655845271,13.9985352 L0.655845271,13.9985352 Z"
          id="Path"></path>
        <path
          d="M4.83632996,7.74023437 C5.1854089,7.74023437 5.53660346,7.74023437 5.8856824,7.74023437 C6.40612735,7.74023437 6.92657231,7.74023437 7.44701727,7.74023437 C8.08170624,7.74023437 8.71639521,7.74023437 9.35108418,7.74023437 C10.0428952,7.74023437 10.7347061,7.74023437 11.4265171,7.74023437 C12.1289063,7.74023437 12.8312954,7.74023437 13.5336845,7.74023437 C14.1874141,7.74023437 13.1289737,7.74023438 13.7827033,7.74023438 C14.3327671,7.74023438 14.8849465,7.74023438 15.4350103,7.74023438 C15.8327487,7.74023438 16.2326028,7.74023438 16.6303412,7.74023438 C16.8186323,7.74023438 17.0069233,7.74267578 17.19733,7.74023438 C17.2057925,7.74023438 17.2142551,7.74023438 17.2206019,7.74023438 C17.5527558,7.74023438 17.8701003,7.40332031 17.8552909,7.0078125 C17.8404815,6.60986328 17.5760278,6.27539063 17.2206019,6.27539063 C17.0915485,6.27539063 16.9624951,6.27539063 16.831326,6.27539063 C16.4822471,6.27539063 16.1310525,6.27539063 15.7819736,6.27539063 C15.2615286,6.27539063 14.7410837,6.27539063 14.2206387,6.27539063 C13.5859498,6.27539063 14.6655465,6.27539062 14.0308575,6.27539062 C13.3390466,6.27539062 12.6472356,6.27539062 11.9554246,6.27539062 C11.2530355,6.27539062 10.5506463,6.27539062 9.84825721,6.27539062 C9.19452757,6.27539062 8.5386823,6.27539062 7.88495266,6.27539062 C7.33488888,6.27539062 6.78270948,6.27539062 6.2326457,6.27539062 C5.83490728,6.27539062 5.43505323,6.27539062 5.03731481,6.27539062 C4.84902374,6.27539062 4.66073268,6.27294922 4.47032599,6.27539062 C4.46186347,6.27539062 4.45340095,6.27539062 4.44705406,6.27539062 C4.11490017,6.27539062 3.79755568,6.61230469 3.81236509,7.0078125 C3.8271745,7.40576172 4.09162824,7.74023437 4.44705406,7.74023437 C4.57610749,7.74023437 4.70516091,7.74023437 4.83632996,7.74023437 Z"
          id="Path"></path>
        <path
          d="M0.655845271,7.74023438 C1.12763074,7.74023438 1.59941621,7.74023438 2.07120168,7.74023438 C2.40335557,7.74023438 2.72070006,7.40332031 2.70589065,7.0078125 C2.69108124,6.60986328 2.4266275,6.27539062 2.07120168,6.27539062 C1.59941621,6.27539062 1.12763074,6.27539062 0.655845271,6.27539062 C0.323691376,6.27539062 0.00634688972,6.61230469 0.0211562991,7.0078125 C0.0359657084,7.40576172 0.298303817,7.74023438 0.655845271,7.74023438 L0.655845271,7.74023438 Z"
          id="Path"></path>
        <path
          d="M4.83632996,1.48193359 C5.1854089,1.48193359 5.53660346,1.48193359 5.8856824,1.48193359 C6.40612735,1.48193359 6.92657231,1.48193359 7.44701727,1.48193359 C8.08170624,1.48193359 8.71639521,1.48193359 9.35108418,1.48193359 C10.0428952,1.48193359 10.7347061,1.48193359 11.4265171,1.48193359 C12.1289062,1.48193359 12.8312954,1.48193359 13.5336845,1.48193359 C14.1874141,1.48193359 13.1289737,1.48193359 13.7827033,1.48193359 C14.3327671,1.48193359 14.8849465,1.48193359 15.4350103,1.48193359 C15.8327487,1.48193359 16.2326028,1.48193359 16.6303412,1.48193359 C16.8186323,1.48193359 17.0069233,1.484375 17.19733,1.48193359 C17.2057925,1.48193359 17.2142551,1.48193359 17.2206019,1.48193359 C17.5527558,1.48193359 17.8701003,1.14501953 17.8552909,0.749511719 C17.8404815,0.3515625 17.5760278,0.0170898437 17.2206019,0.0170898437 C17.0915485,0.0170898437 16.9624951,0.0170898437 16.831326,0.0170898437 C16.4822471,0.0170898437 16.1310525,0.0170898437 15.7819736,0.0170898437 C15.2615286,0.0170898437 14.7410837,0.0170898437 14.2206387,0.0170898437 C13.5859498,0.0170898437 14.6655465,0.0170898437 14.0308575,0.0170898437 C13.3390466,0.0170898437 12.6472356,0.0170898437 11.9554246,0.0170898437 C11.2530355,0.0170898437 10.5506463,0.0170898437 9.84825721,0.0170898437 C9.19452757,0.0170898437 8.5386823,0.0170898437 7.88495266,0.0170898437 C7.33488888,0.0170898437 6.78270948,0.0170898437 6.2326457,0.0170898437 C5.83490728,0.0170898437 5.43505323,0.0170898437 5.03731481,0.0170898437 C4.84902374,0.0170898437 4.66073268,0.0146484375 4.47032599,0.0170898437 C4.46186347,0.0170898437 4.45340095,0.0170898437 4.44705406,0.0170898437 C4.11490017,0.0170898437 3.79755568,0.354003906 3.81236509,0.749511719 C3.8271745,1.14501953 4.09162824,1.48193359 4.44705406,1.48193359 C4.57610749,1.48193359 4.70516091,1.48193359 4.83632996,1.48193359 Z"
          id="Path"></path>
        <path
          d="M0.655845271,1.48193359 C1.12763074,1.48193359 1.59941621,1.48193359 2.07120168,1.48193359 C2.40335557,1.48193359 2.72070006,1.14501953 2.70589065,0.749511719 C2.69108124,0.3515625 2.4266275,0.0170898437 2.07120168,0.0170898437 C1.59941621,0.0170898437 1.12763074,0.0170898437 0.655845271,0.0170898437 C0.323691376,0.0170898437 0.00634688972,0.354003906 0.0211562991,0.749511719 C0.0359657084,1.14501953 0.298303817,1.48193359 0.655845271,1.48193359 L0.655845271,1.48193359 Z"
          id="Path"></path>
      </g>
    </g>
  </g>
</svg>
