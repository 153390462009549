import { Injectable } from '@angular/core';

import { siteFlag, siteKey } from 'src/environments/environment';

@Injectable( {
  providedIn: 'root'
} )
export class FlagCheckerService {

  siteKey = siteFlag;
  siteFlag = siteFlag;

  constructor () { }


  getSiteKey () {
    if ( siteFlag.isMyAdviser ) {
      return siteKey.myAdviser;
    } else if ( siteFlag.isLoanMarket ) {
      return siteKey.loanMarket;
    }
  }

}
