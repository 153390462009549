import { Component, OnInit } from '@angular/core';

// environment
import { googleTagManager } from '../environments/environment';

// services
// import { FlagCheckerService } from '../app/services/flag-checker/flag-checker.service';
import { NavigationEnd, Router, ActivationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
} )
export class AppComponent implements OnInit {

  title = 'Mortgage Deferment Calculator';

  constructor (
    private router: Router,
    private gtmService: GoogleTagManagerService,
  ) {
  }

  ngOnInit (): void {
    let currentUrl = '';
    this.router.events.forEach( event => {
      if ( googleTagManager.allow &&
        event instanceof ActivationEnd &&
        !event.snapshot.data.iframe &&
        currentUrl !== this.router.url.toString() ) {
        currentUrl = this.router.url.toString();
        const gtmTag = {
          page: currentUrl,
          title: event.snapshot.data.pageTitle
        };

        this.gtmService.pushTag( gtmTag );
      }
    } );
  }

}
