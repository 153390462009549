import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component( {
  // tslint:disable-next-line: component-selector
  selector: 'icon-arrow-up',
  templateUrl: './icon-arrow-up.component.html',
  styles: []
} )
export class IconArrowUpComponent implements AfterViewInit {

  @Input() color;

  @ViewChild( 'iconArrowUp' ) el: ElementRef;

  ngAfterViewInit () {
    if ( this.el ) {
      this.el.nativeElement.style.fill = `#${this.color}`;
    }
  }
}
