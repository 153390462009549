import { Component, OnInit, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

// constants
import { PAYMENTS_OPTIONS, RESULT_TABS, LM_COLOR_DEFAULTS, COUNTRIES } from '../../constants/mortgage-holiday-calc';

// api
import { MortgageHolidayCalcService } from '../../api/mortgage-holiday-calc/mortgage-holiday-calc.service';

// services
import { FlagCheckerService } from '../../services/flag-checker/flag-checker.service';

// environment
import { siteKey as SITE_KEY_REF, siteKey } from '../../../environments/environment';



@Component( {
  selector: 'app-mortgage-holiday-calc',
  templateUrl: './mortgage-holiday-calc.component.html',
  styleUrls: ['./mortgage-holiday-calc.component.scss']
} )


export class MortgageHolidayCalcComponent implements OnInit, AfterViewInit {

  siteKeyRef = SITE_KEY_REF;
  LM_COLOR_DEFAULTS = LM_COLOR_DEFAULTS;
  COUNTRIES = COUNTRIES;
  siteKey;
  isIframe;
  calculatorForm: FormGroup;
  remainingYearsOptions;
  remainingMonthsOptions;
  result;
  paymentOptions;
  selectedPayment;
  paymentResults;
  selectedYear;
  yearOptions;
  yearResults;
  openResults;
  isMobileTablet;
  selectedTab;
  RESULT_TABS;
  showLeftArrow;
  isCalculating;
  hexColor;
  country;

  @ViewChild( 'remainingYearsId' ) remainingYearsElement: ElementRef;
  @ViewChild( 'remainingMonthsId' ) remainingMonthsElement: ElementRef;
  @ViewChild( 'paymentOptionsId' ) paymentOptionsElement: ElementRef;
  @ViewChild( 'yearOptionsId' ) yearOptionsElement: ElementRef;


  @HostListener( 'window:resize', ['$event'] )
  onResize () {
    setTimeout( () => {
      this.isMobileTablet = this.validateIsMobileTablet();
    }, 250 );
  }


  constructor (
    private formBuilder: FormBuilder,
    private mohcService: MortgageHolidayCalcService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private flagCheckerService: FlagCheckerService ) {

    this.siteKey = activatedRoute.snapshot.data.key;
    this.isIframe = activatedRoute.snapshot.data.iframe;
  }

  ngOnInit (): void {

    this.title.setTitle( this.activatedRoute.snapshot.data.pageTitle );

    this.calculatorForm = this.formBuilder.group( {
      currentBalance: ['', Validators.required],
      remainingYear: [30],
      remainingMonth: [0],
      interestRate: ['', Validators.required],
      monthsHoliday: ['', Validators.required],
      option: ['1', Validators.required]
    } );

    this.remainingYearsOptions = [...Array( 40 ).keys()].map( x => ++x );
    this.remainingMonthsOptions = [...Array( 11 ).keys()].map( x => ++x );
    this.paymentOptions = PAYMENTS_OPTIONS;
    this.selectedPayment = this.paymentOptions[0];
    this.isMobileTablet = this.validateIsMobileTablet();
    this.RESULT_TABS = RESULT_TABS;
    this.selectedTab = RESULT_TABS.RESULTS;
    this.showLeftArrow = false;
    this.openResults = false;
    this.isCalculating = false;
    this.hexColor = '';
    this.country = COUNTRIES.NEW_ZEALAND;
    this.checkUrlQueryParams();

  }

  ngAfterViewInit () {
    this.setSelectOptionPosition( '#remaining-years-options', this.calculatorControls.remainingYear.value );
  }


  checkUrlQueryParams () {
    this.activatedRoute.queryParamMap.subscribe( params => {
      const calculatorValue: any = { ...params };

      const color = calculatorValue.params.color;
      this.initCustomTheme( color );

      if ( calculatorValue.params.country ) {
        this.country = calculatorValue.params.country;
        if ( this.country === COUNTRIES.AUSTRALIA && !this.isIframe ) {
          if ( this.siteKey === this.siteKeyRef.loanMarket ) {
            this.title.setTitle( `Payment Pause Calculator (LM)` );
          } else {
            this.title.setTitle( `Payment Pause Calculator` );
          }
        }
      }

      this.calculatorControls.currentBalance.setValue( calculatorValue.params.currentBalance );
      this.formatCurrentBalance( true );

      const paramsRemainingYear = parseInt( calculatorValue.params.remainingYear, 10 );
      if ( paramsRemainingYear ) {
        this.calculatorControls.remainingYear.setValue( paramsRemainingYear );
      }

      this.calculatorControls.remainingMonth.setValue( parseInt( calculatorValue.params.remainingMonth, 10 ) );

      this.calculatorControls.interestRate.setValue( calculatorValue.params.interestRate );
      this.formatInterestRate( true );

      this.calculatorControls.monthsHoliday.setValue( calculatorValue.params.monthsHoliday );
      this.formatMonthsHoliday( true );


      if ( calculatorValue.params.hasOwnProperty( 'interestOnly' ) ) {
        if ( calculatorValue.params.interestOnly === 'true' ) {
          this.calculatorControls.option.setValue( '3' );
        } else {
          this.calculatorControls.option.setValue( '1' );
        }
      } else {
        this.calculatorControls.option.setValue( calculatorValue.params.option || '1' );
      }

      this.calculate();
    } );
  }

  getYearOptions () {

    let temp = [];
    if ( this.result ) {
      this.result.mortgageList.map( list => {
        if ( !temp.includes( list.year ) ) {
          temp.push( list.year );
        }
      } );

      temp = temp.sort( ( a, b ) => {
        return a - b;
      } );

      this.yearOptions = temp.map( ( year, ind ) => {
        return { year, name: `Year ${ind + 1} - ${year}`, id: ind };
      } );


      this.selectedYear = this.yearOptions.length > 0 && this.yearOptions[0] || '';
      this.getYearResult( this.selectedYear );
    }
  }

  getPaymentResult ( paymentOption ) {
    this.selectedPayment = paymentOption;
    if ( this.result ) {
      this.paymentResults = this.selectedPayment.id === 1 ? this.result.monthlyMortgage : this.result.fortnightlyMortgage;
    }
  }

  getYearResult ( yearOption ) {
    this.selectedYear = yearOption;
    if ( this.result ) {
      this.yearResults = this.result.mortgageList.filter( list => list.year === this.selectedYear.year );
      this.yearResults = this.yearResults.sort( ( a, b ) => ( a.month > b.month ) ? 1 : -1 );
    }
  }

  get calculatorControls () {
    return this.calculatorForm.controls;
  }


  validateIsMobileTablet () {
    let viewportSize;
    const viewportMaxMobileTablet = 1024;

    if ( !this.isIframe ) {
      if ( /iPad|iPhone|iPod|Macintosh/.test( navigator.userAgent ) ) {
        viewportSize = screen.width || window.innerWidth;
      } else {
        viewportSize = window.outerWidth || window.innerWidth;
      }
      return viewportSize <= viewportMaxMobileTablet;
    }
    return true;
  }

  resetSelectOption ( key ) {

    switch ( key ) {
      case 'remainingYears':
        this.remainingYearsElement.nativeElement.checked = false;
        break;
      case 'remainingMonths':
        this.remainingMonthsElement.nativeElement.checked = false;
        break;
      case 'paymentOptions':
        this.paymentOptionsElement.nativeElement.checked = false;
        break;
      case 'yearOptions':
        this.yearOptionsElement.nativeElement.checked = false;
        break;
      default:
        break;
    }

  }

  formatNumber ( n ) {
    return n.replace( /\D/g, '' ).replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
  }

  formatCurrentBalance ( blur = false ) {
    const inputVal = this.calculatorControls.currentBalance.value;
    this.calculatorControls.currentBalance.setValue( this.formatCurrency( inputVal, blur ) );
  }

  formatCurrency ( input, blur = false ) {

    if ( !input ) { return; }

    let inputVal = input.toString();

    if ( inputVal.indexOf( '.' ) >= 0 ) {
      const decimalPos = inputVal.indexOf( '.' );
      let leftSide = inputVal.substring( 0, decimalPos );
      let rightSide = inputVal.substring( decimalPos );

      leftSide = this.formatNumber( leftSide.replace( '$', '' ).replace( /,/g, '' ).replace( /^0+/, '' ) );

      if ( !leftSide ) {
        leftSide = '0';
      }
      if ( rightSide.length > 3 ) {
        rightSide = rightSide.substring( 0, 3 );
      }
      rightSide = this.formatNumber( rightSide );


      if ( blur ) {
        rightSide += '00';
      }

      rightSide = rightSide.substring( 0, 2 );
      inputVal = '$' + leftSide + '.' + rightSide;

    } else {

      inputVal = inputVal.replace( '$', '' ).replace( /,/g, '' ).replace( /^0+/, '' );
      if ( !inputVal ) {
        inputVal = '0';
      }
      inputVal = this.formatNumber( inputVal );
      inputVal = '$' + inputVal;

      if ( blur ) {
        inputVal += '.00';
      }
    }
    return inputVal;
  }

  formatInterestRate ( blur = false ) {
    let inputVal = this.calculatorControls.interestRate.value;

    if ( !inputVal ) { return; }

    if ( inputVal.indexOf( '.' ) >= 0 ) {
      const decimalPos = inputVal.indexOf( '.' );
      let leftSide = inputVal.substring( 0, decimalPos ).replace( /\D/g, '' );
      let rightSide = inputVal.substring( decimalPos ).replace( /\D/g, '' );

      leftSide = leftSide.replace( /^0+/, '' );

      if ( blur ) {
        rightSide += '00';
      }

      rightSide = rightSide.substring( 0, 2 );
      inputVal = leftSide + '.' + rightSide;

    } else {
      inputVal = inputVal.replace( /\D/g, '' ).replace( /^0+/, '' );

      if ( blur ) {
        inputVal += '.00';
      }
    }
    this.calculatorControls.interestRate.setValue( inputVal );
  }

  formatMonthsHoliday ( blur = false ) {
    let inputVal = this.calculatorControls.monthsHoliday.value;

    if ( !inputVal ) { return; }
    inputVal = inputVal.replace( /\D/g, '' ).replace( /^0+/, '' );
    this.calculatorControls.monthsHoliday.setValue( inputVal );
  }

  isMonthsHolidayValid () {
    const { monthsHoliday, remainingMonth, remainingYear } = this.calculatorForm.value;

    if ( !remainingYear && parseInt( monthsHoliday, 10 ) >= remainingMonth ) {
      return false;
    }
    return true;

  }

  selectRemainingYear ( year ) {
    this.calculatorControls.remainingYear.setValue( year );
    this.setSelectOptionPosition( '#remaining-years-options', year );

  }

  selectRemainingMonth ( month ) {
    this.calculatorControls.remainingMonth.setValue( month );

  }

  selectPayment ( payment ) {
    this.selectedPayment = payment;
  }

  abbreviateMonth ( month ) {
    return month.substring( 0, 3 );
  }

  calculate () {
    if ( this.canCalculate() ) {
      this.getResults();
    }
  }

  canCalculate () {
    const { currentBalance, remainingMonth, remainingYear, interestRate, monthsHoliday } = this.calculatorForm.value;
    const parsedCurrentBalance = parseInt( currentBalance && currentBalance.replace( '$', '' ).replace( /,/g, '' ) || '0', 10 );
    const parsedInterestRate = parseInt( interestRate && interestRate.replace( /,/g, '' ) || '0', 10 );
    const parsedMonthsHoliday = parseInt( monthsHoliday && monthsHoliday || '0', 10 );
    return this.calculatorForm.valid
      && this.isMonthsHolidayValid()
      && ( ( isNaN( remainingYear ) ? false : !!remainingYear ) || ( isNaN( remainingMonth ) ? false : !!remainingMonth ) )
      && ( isNaN( parsedCurrentBalance ) ? false : !!parsedCurrentBalance )
      && ( isNaN( parsedInterestRate ) ? false : !!parsedInterestRate )
      && ( isNaN( parsedMonthsHoliday ) ? false : !!parsedMonthsHoliday )
      && !this.isCalculating;
  }

  getResults () {
    this.isCalculating = true;
    const params = this.calculatorForm.value;
    params.currentBalance = params.currentBalance.replace( '$', '' ).replace( /,/g, '' );
    params.remainingYear = isNaN( params.remainingYear ) ? 0 : params.remainingYear;
    params.remainingMonth = isNaN( params.remainingMonth ) ? 0 : params.remainingMonth;
    params.interestRate = params.interestRate.replace( /,/g, '' );
    params.monthsHoliday = params.monthsHoliday.replace( /,/g, '' );

    this.mohcService.getResults( params ).subscribe( result => {
      this.result = result;

      if ( this.isMobileTablet ) {
        this.openResults = true;
      }
      this.yearOptions = [];
      this.getYearOptions();

      const routeParams = params;
      routeParams.color = this.siteKey === this.siteKeyRef.byob && this.hexColor || null;


      this.getPaymentResult( this.selectedPayment );
      this.router.navigate( [], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...routeParams
        },
        queryParamsHandling: ''
      } );
      this.isCalculating = false;
    } );
  }

  parseNumber ( strNum ) {
    return parseFloat( strNum );
  }

  setSelectOptionPosition ( elementId, optionPositionNumber = 0 ) {
    const el = document.querySelector( elementId );
    const optionHeightPx = el.querySelector( '.option:first-child' ).clientHeight;
    el.scrollTop = optionHeightPx * optionPositionNumber;
  }

  initCustomTheme ( hex = LM_COLOR_DEFAULTS.BLUE ) {
    const hexRegEx = /^#([\da-f]{3}){1,2}$/i;

    if ( !hexRegEx.test( `#${hex}` ) ) {
      hex = LM_COLOR_DEFAULTS.BLUE;
    }

    this.hexColor = hex;
    const rgb = this.hexToRGB( `#${hex}` );
    const threshold = 0.75;
    const borderThreshold = 0.8;
    const perceivedLightness = this.sRGBLumaMethod( rgb );
    const lightness = ( perceivedLightness - threshold ) * -10000000;
    const borderAlpha = ( perceivedLightness - borderThreshold ) * 100;

    document.documentElement.style.setProperty( '--base-color', `#${hex}` );
    document.documentElement.style.setProperty( '--font-color', `hsl(${0}, ${0}%, ${lightness}%)` );
    document.documentElement.style.setProperty( '--border-color', `rgba(${rgb.r - 50}, ${rgb.g - 50}, ${rgb.b - 50}, ${borderAlpha})` );
    document.documentElement.style.setProperty( '--transparent-base-color', `#${hex}0d` );
  }

  hexToRGB ( hex ) {
    let red = '';
    let green = '';
    let blue = '';

    if ( hex.length === 4 ) {
      red = `0x${hex[1]}${hex[1]}`;
      green = `0x${hex[2]}${hex[2]}`;
      blue = `0x${hex[3]}${hex[3]}`;

    } else if ( hex.length === 7 ) {
      red = `0x${hex[1]}${hex[2]}`;
      green = `0x${hex[3]}${hex[4]}`;
      blue = `0x${hex[5]}${hex[6]}`;
    }

    return {
      r: parseInt( red, 16 ),
      g: parseInt( green, 16 ),
      b: parseInt( blue, 16 )
    };

  }

  sRGBLumaMethod ( rgb ) {
    return ( ( ( rgb.r * 0.2126 ) + ( rgb.g * 0.7152 ) + ( rgb.b * 0.0722 ) ) / 255 );
  }



}
