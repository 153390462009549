<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
  <g id="calculator" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g #iconArrowUp id="icon-arrow-up" transform="translate(-333.000000, -556.000000)" fill="#FFFFFF"
      fill-rule="nonzero">
      <g id="noun_Arrow_89790" transform="translate(333.000000, 556.000000)">
        <polygon id="icn-arrow-up"
          points="6 0 0 6 3.42857143 6 3.42857143 11.7142857 8.57142857 11.7142857 8.57142857 6 12 6"></polygon>
      </g>
    </g>
  </g>
</svg>
